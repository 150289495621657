@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

$text-gray: #434857;
$yellow: #c4a700;
$body-bg: $gray-900; 

// Brand Scale
$theme-colors: (
  primary: $yellow,
  secondary: $gray-600,
  success: #19AB27,
  info: #0159fe,
  warning: #e4d836,
  danger: #e64759,
  dark: darken(#252830, 5%),
  light: $light
);

$btn-primary-color: black;
$form-check-input-checked-bg-color : map-get($theme-colors, 'success');
$form-label-font-weight: bold;

$border-color: #444857;

// Dropdowns
$dropdown-link-active-bg: $gray-900;

// Local variables
$dark-yellow: #786600;
$light-grey: #cfd2da;

// icons
$icon-font-name: 'toolkit-entypo';
$icon-font-path: '/assets/fonts/';

// States
$state-success-text: map-get($theme-colors,'success');
$state-info-text: map-get($theme-colors,'info');
$state-warning-text: map-get($theme-colors,'warning');
$state-danger-text: map-get($theme-colors,'danger');

// Spacing
$spacer: 20px;
$spacer-x: $spacer;
$spacer-y: $spacer;

// Fonts
$font-family: 'Open Sans', Helvetica, Arial, sans-serif;
$font-family-sans-serif: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-base: $font-family-sans-serif;

// Body
$font-size-base: 0.9rem;
$font-weight-base: 300;
$letter-spacing-base: 0;
$line-height-base: 1.5;

// Lead
$lead-font-size: $font-size-base * 1.15;
$lead-font-size-sm: $font-size-base * 1.5;
$lead-font-weight: inherit;
$lead-line-height: 1.4;
$lead-letter-spacing: 0;

// pagination
$pagination-disabled-border-color: rgba(53, 54, 55, 0.78);

// cards
$card-bg: rgba(255, 255, 255, 0.07);

// Custom
$btn-font-size: $font-size-base;
$btn-text-transform: none;
$btn-letter-spacing: normal;
$btn-font-weight: normal;
$btn-secondary-bg: $gray-100;
$btn-secondary-border: darken($btn-secondary-bg, 10%);
$btn-toolbar-divider-bg: #eee;
$btn-link-color: $yellow;

// Alerts
$alert-success-bg: map-get($theme-colors,'success');
$alert-success-text: lighten(map-get($theme-colors,'success'), 50%);
$alert-success-border: $alert-success-bg;

$alert-info-bg: map-get($theme-colors,'info');
$alert-info-text: lighten(map-get($theme-colors,'info'), 50%);
$alert-info-border: $alert-info-bg;

$alert-warning-bg: map-get($theme-colors,'warning');
$alert-warning-text: lighten(map-get($theme-colors,'warning'), 50%);
$alert-warning-border: $alert-warning-bg;

$alert-danger-bg: map-get($theme-colors,'danger');
$alert-danger-text: lighten(map-get($theme-colors,'danger'), 45%);
$alert-danger-border: $alert-danger-bg;

// Navs
$nav-link-padding: 7px 15px;

$nav-bordered-color: #777;
$nav-bordered-color-active: #777;
$nav-bordered-font-weight: 300;
$nav-bordered-font-weight-active: 500;

$nav-pills-active-weight: 500;
$nav-header-color: lighten($gray-700, 35%);

// Navbar
$navbar-border: transparent;
$navbar-inverse-bg: $gray-900;
$navbar-inverse-border: $gray-900;
$navbar-brand-padding-y: 0.125rem;

$statlist-border-color: #e5e5e5;
$statlist-link-color: inherit;
$statlist-progress-bg: #f5f5f5;

$hr-divider-content-color: $gray-700;
$hr-divider-content-bg: $body-bg;
$hr-divider-before-bg: $gray-300;

$custom-select-color: $input-color;
$custom-select-bg: $input-bg;
$custom-select-disabled-bg: $input-disabled-bg;

$custom-control-indicator-bg: $input-bg;
$custom-control-indicator-box-shadow: rgba(0, 0, 0, 0.15);

$sidebar-brand-color: $gray-700;
$sidebar-brand-hover-color: $gray-800;
$sidebar-toggler-color: #ccc;
$sidebar-toggler-hover-color: #fff;

$dashhead-toolbar-divider-bg: #eee;
$dashhead-subtitle-color: $nav-header-color;

// $well-border: rgba(0,0,0,.1);

$navbar-nav-active-font-weight: 500;

// Container
$container-max-widths: (
  md: 880px,
  lg: 950px,
  xl: 1200px
);

$accordion-bg: transparent;
$accordion-button-color: #fff;

.accordion-button:not(.collapsed)
{
  color: #212529;
}
.accordion-button:focus {
  border-color: none;
  box-shadow: none;
}

$offcanvas-horizontal-width: 600px;

@include _assert-ascending($container-max-widths, '$container-max-widths');

// Gray scale
$gray-900: #252830; //#111
$gray-100: lighten($gray-900, 93.5%); // f5f5f5
$gray-200: lighten($gray-900, 80%);
$gray-300: lighten($gray-900, 66.7%);
$gray-400: lighten($gray-900, 45.5%);
$gray-500: lighten($gray-900, 33.5%); // #aaa
$gray-600: lighten($gray-900, 28.7%);
$gray-700: lighten($gray-900, 20%); //#555
$gray-800: lighten($gray-900, 13.5%); //#222

// Colors
$body-color: $gray-300;

// Typography
$headings-color: $gray-100;
$headings-font-weight: 300;
$code-bg: darken($body-bg, 5%);
$blockquote-border-color: $gray-800;
$hr-border-color: $gray-800;
$hr-border: $gray-300;
$text-muted: #999;

// Tables
$table-bg-accent: $gray-800;
$table-border-color: $gray-800;
$table-bg-hover: $gray-800;

// Forms
$input-bg: $gray-800;
$input-border-color: $gray-800;
$input-disabled-bg: lighten($gray-900, 5%);
$input-color: #fff;
$input-border: $gray-800;
$input-border-focus: #fff;
$input-group-addon-bg: lighten($gray-900, 18%);
$input-group-addon-border-color: transparent;
$input-color-placeholder: $gray-300;
$input-weight: 300;

$form-select-bg: $gray-800;
$form-select-border-color: $gray-800;
$form-select-disabled-bg: lighten($gray-900, 5%);
$form-select-color: #fff;
$form-select-border: $gray-800;
$form-select-border-focus: #fff;
$form-select-group-addon-bg: lighten($gray-900, 18%);
$form-select-group-addon-border-color: transparent;
$form-select-color-placeholder: $gray-300;

// Progress bars
$progress-bg: $gray-800;

// Navs
$nav-link-hover-bg: $gray-800;
$nav-pills-active-link-color: $gray-900;
$nav-pills-active-link-bg: map-get($theme-colors,'primary');
$nav-tabs-border-color: $gray-800;
$nav-tabs-active-link-hover-color: $component-active-color;
$nav-tabs-active-link-hover-bg: transparent;
$nav-tabs-active-link-hover-border-color: $nav-tabs-border-color;

// Inverted navbar
$navbar-inverse-color: $gray-300;
$navbar-inverse-bg: darken($gray-900, 5%);
$navbar-inverse-border: darken($gray-900, 5%);

// Breadcrumbs
$breadcrumb-bg: lighten($gray-900, 5%);
$breadcrumb-active-color: $component-active-color;

// List groups
$list-group-bg: transparent;
$list-group-header-color: inherit;
$list-group-color:  $light-grey;
$list-group-border: $gray-800;
$list-group-hover-bg: $gray-800;
$list-group-action-color: $gray-300;
$list-group-action-hover-color: $gray-300;
$list-group-action-heading-color: $gray-300;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $gray-700;
$list-group-active-border: $gray-700;
$list-group-active-text-color: lighten($list-group-active-bg, 40%);
$list-group-disabled-color: $gray-700;
$list-group-disabled-bg: transparent;
$list-group-disabled-text-color: inherit;
$list-group-border-color: #434857;

// Tooltips
$tooltip-arrow-color: #fff;

$thumbnail-bg: transparent;
$thumbnail-border-color: lighten($gray-900, 5%);

$badge-color: $body-bg;
$badge-bg: #fff;

$btn-toolbar-divider-bg: $gray-800;

$custom-select-color: $gray-300;
$custom-select-bg: $gray-800;
$custom-select-disabled-bg: darken($gray-800, 10%);

$custom-control-indicator-bg: $gray-800;
$hr-divider-content-color: $gray-500;
$hr-divider-content-bg: $gray-900;
$hr-divider-before-bg: $gray-800;

$nav-header-color: $gray-700;
$nav-tabs-border-color: $gray-900;
$nav-bordered-color-active: map-get($theme-colors,'primary');
$nav-bordered-color: #fff;

$list-group-header-color: #fff;
$list-group-border: $gray-800;

$tooltip-bg: #fff;
$tooltip-color: $body-bg;

$statlist-border-color: $gray-800;
$statlist-link-color: #fff;
$statlist-progress-bg: lighten($body-bg, 5%);

$sidebar-toggler-color: $gray-800;

$dashhead-toolbar-divider-bg: $gray-800;
$dashhead-subtitle-color: $gray-800;

// Icon nav
$iconav-bg: lighten($gray-900, 5%);
$iconav-border: #fff;
$iconav-width: 70px;
$iconav-brand-border: $iconav-border;
$iconav-brand-bg: darken($gray-900, 10%);
$iconav-brand-color: $gray-500;
$iconav-brand-hover-color: $gray-300;
$iconav-link-color: inherit;
$iconav-link-active-color: $gray-700;
$iconav-link-border: $gray-900;
$iconav-link-active-bg: $gray-900;

$sidebar-brand-color: $gray-700;
$sidebar-brand-hover-color: $gray-500;