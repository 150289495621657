.react-select-container {
  width: 100%;
}

.react-select__control {
  font-size: $font-size-base;
  line-height: $input-line-height;
  color: $input-color !important;
  background-color: $input-bg !important;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color !important;
}

.react-select__dropdown-indicator {
  color: hsl(0, 0%, 50%);
}

.react-select__indicator-separator {
  background-color: hsl(0, 0%, 50%) !important;
}

.react-select__single-value {
  color: #fff !important;
}

.react-select__control--is-focused {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #feee81 !important;
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(252, 220, 3, 0.25) !important;
  box-shadow: 0 0 0 0.2rem rgba(252, 220, 3, 0.25) !important;

  .react-select__single-value {
    color: #0d0f11 !important;
  }
}

.react-select__menu-list,
.react-select__option {
  background-color: #fff !important;
  color: #0d0f11 !important;
}

.react-select__option:hover,
.react-select__option:focus {
  background-color: #aaa !important;
  color: #0d0f11 !important;
  font-weight: 500;
  cursor: pointer;
}

.react-select__option--is-focused {
  background-color: #ddd !important;
}

.react-select__menu {
  z-index: 100 !important;
}
