@import 'theme.scss';

.ratio.ratio-1x1 {
  height: 35px;
  width: 35px;
}

.ami-red {
  background-color: red;
  color: #fff;
}

.ami-green {
  background-color: green;
  color: #fff;
}

.ami-blue {
  background-color: blue;
  color: #fff;
}

.ami-yellow {
  background-color: yellow;
  color: #fff;
}

.ami-gray {
  background-color: gray;
  color: #fff;
}

.ami-black {
  background-color: black;
  color: #fff;
}

