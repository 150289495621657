@import "./variables";

body {
  margin: 0;
  font-size: .9rem;
  line-height: 1.5;
  color: $light-grey;
  text-align: left;
  background-color: #252830;
}

.bg-dark, .offcanvas {
  background-color: #1a1c22 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}
.btn, .btn:hover {
  color: white;
  width: auto;
  white-space: nowrap;
}

.btn-block,
.btn-block:hover {
  display: block;
  width: 100%;
}

.btn-primary {
  color: #212529;
}
.btn-primary:hover {
  color: white;
}
.btn-success {
  color: #fff !important;
  background-color: #19ab27;
  border-color: #19ab27;
}
.btn-success:hover {    
  background-color: #148a1f;
  border-color: #127f1d;
  text-decoration: none;
}
.btn-secondary {
  color: white;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem hsla(208,7%,46%,.5);
}

a:not(.dropdown-item), .btn-link:not(*:focus-visible) {
  color: $yellow;
  text-decoration: none;
}

a:hover, .btn-link:hover {
  color: $dark-yellow;
  text-decoration: underline;
}
a:active, .btn-link:active {
  color: $dark-yellow !important;
}

.btn-link:focus-visible {
  border: none !important;
  box-shadow: none !important;
}

.nav-item {
  text-decoration: none;
}
.nav-item:hover {
  color: white !important;
  text-decoration: none;
}
.nav .dropdown-item {
  font-size: .9rem;
}
.nav .dropdown-item:hover {
  background-color: #dab14b;
  color: #16181b;
  text-decoration: none;
}
.navbar-brand:hover {
  text-decoration: none;
}

.nav-link, .nav-link:hover {
  color: white;
  width: auto;
  white-space: nowrap;
}

.nav-link-primary {
  color: $yellow;
  background-color: transparent;
  background-image: none;
  border-color: $yellow;
}
.nav-link-primary:hover {
  color: #212529;
  background-color: #c4a700;
  border-color: #c4a700;
}
.nav-link-primary.active{
  color: #212529 !important;
  background-color: #c4a700 !important;
  border-color: #c4a700 !important;
  text-decoration: none;
}

.btn-outline-primary {
  color: $yellow;
  background-color: transparent;
  background-image: none;
  border-color: $yellow;
}
.btn-outline-primary:hover {
  color: #212529;
  background-color: #c4a700;
  border-color: #c4a700;
}
.btn-outline-primary.active{
  color: #212529 !important;
  background-color: #c4a700 !important;
  border-color: #c4a700 !important;
  text-decoration: none;
}

.form-group {
  margin-bottom: 1rem;
  display: grid;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row>.col, .form-row>[class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check-input {
  margin-top: 0.3rem;
  width: 1em;
  height: 1em;
}
.form-control {
  font-size: inherit;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ffe345;
  border-top-color: rgb(255, 227, 69);
  border-right-color: rgb(255, 227, 69);
  border-bottom-color: rgb(255, 227, 69);
  border-left-color: rgb(255, 227, 69);
  outline: 0;
  outline-color: initial;
  outline-style: initial;
  outline-width: 0px;
  box-shadow: 0 0 0 0.2rem rgba(196,167,0,.25);
}

.alert {
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.concealable-wrapper {
  .alert {
    width: initial;
  }
}

label {
  margin-bottom: 0.25rem;
}

.badge {
  font-weight: 700 !important;
}
.text-bg-danger {
  color: white !important;
}
.text-bg-secondary {
  color: #fff;
  background-color: #6c757d;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: white;
  background-color: #c4a700;
}

.nav-link:focus, .nav-link:hover {
  color: white !important;
  text-decoration: none;
}

.table {
  tr {
    border-bottom-color: #444857;
  }
}
.table td, .table th {
  border-top: 1px solid #434857;
}

.highlighted {
  border: 1px solid red;
  border-radius: 4px;
}

.modal, .option-set-form {
  .list-group-item,
  .table {
    color: $text-gray !important;
  }
}

.btn-outline-danger:hover {
  color: white !important;
}